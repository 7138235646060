import React from 'react';
import './PricingCards.css';
import checkGreenIcon from '@assets/images/icons/check-rounded-green.svg';
import Button from '@shared/Button/Button';
import { Link } from 'react-router-dom';

const PricingCards = ({ title, price, description, features, buttonText, link, isHighlighted }) => {
  return (
    <div className={`pricing-card ${isHighlighted ? 'highlighted' : ''}`}>
      <div className="pricing-card-top-content">
        <div className="pricing-card-title-section">
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
        <p className={`pricing-price ${price === 'Contact Sales for Pricing' ? 'contact-sales-price' : ''}`}>
          {price} {price !== 'Contact Sales for Pricing' && <span className="month-text">/mo</span>}
        </p>
      </div>

      <ul className="pricing-features">
        <h4 className="included-heading">What's included:</h4>
        {features.map((feature, index) => (
          <li key={index} className="feature-item">
            <img src={checkGreenIcon} alt="" />
            <p>{feature}</p>
          </li>
        ))}
      </ul>
      <Link to={link} className='pricing-button-link'>
        <Button className="pricing-button common-button">{buttonText}</Button>
      </Link>
    </div>
  );
};

export default PricingCards;

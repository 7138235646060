import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import "./ContentSwitch.css";

const ContentSwitch = ({ data, onSelect, renderHeader, renderBody, isImage = false }) => {
  const [selectedSuite, setSelectedSuite] = useState(0);

  const handleAccordionClick = (index) => {
    setSelectedSuite(index);
    if (onSelect) {
      onSelect(index);
    }
  };

  return (
    <div className="software-suites-content">
      <Accordion defaultActiveKey={[selectedSuite.toString()]} alwaysOpen={false} className="custom-accordion">
        {data.map((suite, index) => (
          <Accordion.Item key={index} eventKey={index.toString()} onClick={() => handleAccordionClick(index)} className="custom-accordion-item">
            <Accordion.Header>
              {renderHeader ? renderHeader(suite, index, selectedSuite === index) : <div>{suite.title}</div>}
            </Accordion.Header>
             <Accordion.Body className="custom-accordion-body">
              {renderBody ? renderBody(suite, index) : null}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
      {isImage && (
        <div className="software-image">
          <img src={data[selectedSuite].image} alt={data[selectedSuite].title} />
        </div>
      )}
    </div>
  );
};

export default ContentSwitch;

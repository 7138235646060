import React from 'react';
import NavBar from '@shared/Nav/Nav';
import PricingPlans from '../Shared/PricingPlans/PricingPlans';

const privateAttorneysPoints = [
  'Access to Web Dashboard',
  'Brief Drafting Assistance',
  'Case Motion Recommendations',
  'Docket Fetcher',
  'Hallucination Detection',
  'Case Citation Assistance',
  'Chatbot Support',
];

const PrivateAttorney = () => {
  return (
    <div className='firms-container'>
      <NavBar />
      <PricingPlans
        title="Private Attorneys"
        description="Optimize your legal practice with essential AI tools."
        price="499"
        buttonText="Book a Demo"
        features={privateAttorneysPoints}
        showIncludedHeading={false}
        link='/book-a-demo'
      />
    </div>
  );
};

export default PrivateAttorney;

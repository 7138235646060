import React, { useState } from 'react';
import './LitigAI.css';
import litigLogo from '@assets/images/logos/litig-ai-logo.svg';
import Button from '@shared/Button/Button';
import litigLeftImage from '@assets/images/productImages/litig-left-img.png';
import PlayIcon from '@assets/images/icons/play-icon.svg';
import fileIcon from '@assets/images/icons/file-with-background.svg';
import eyeIcon from '@assets/images/icons/eye.png';
import closeIcon from '@assets/images/icons/close-icon.svg';
import { litigAIDataArray, litigMotionData } from './litigMotionData';
import { Link } from 'react-router-dom';

const LitigAI = () => {
  const [showGeneratingMotion, setShowGeneratingMotion] = useState(true);
  const [showMotionPrompt, setShowMotionPrompt] = useState(true);
  const [motionPrompt, setMotionPrompt] = useState('');

  return (
      <section className="litig-ai-section-container">
        <div className="litig-ai-container">
          <div className="litig-ai-left-content">
            <div className="litig-ai-icon-section">
              <Link to="https://www.litigai.org/">
                <img src={litigLogo} alt="litig ai text" className="litig-ai-text-logo" />
              </Link>
            </div>
            <div className="litig-ai-title-section">LitigAI: Transform Federal Civil Practice</div>
            <div className="litig-ai-paragrapgh-content">
              Experience unparalleled efficiency and accuracy in federal civil litigation with LitigAI. Our AI-powered platform analyzes case dockets, predicts
              outcomes, and recommends actionable legal motions—all in real-time. Designed to assist attorneys in navigating complex legal landscapes, LitigAI
              ensures you have the tools you need to excel in every case. With AI-driven workflows, interactive dashboards, and powerful research tools, LitigAI
              streamlines your litigation process, reduces manual tasks, and delivers data-driven legal insights to improve outcomes.
            </div>
            <div className="litig-ai-features-data">
              {litigAIDataArray &&
                litigAIDataArray.map((feature, index) => (
                  <div key={index} className="feature-item">
                    <img src={feature.image} alt="left arrow" className="feature-icon" />
                    <span className="feature-text">{feature.text}</span>
                  </div>
                ))}
            </div>
            <div className="litig-ai-button-section">
              <Button>
                <img src={PlayIcon} alt="play icon" />
                Watch Video
              </Button>
              <Button>
                <Link to="https://www.litigai.org/">Visit LitigAI</Link>
              </Button>
            </div>
          </div>

          <div className="ligit-ai-right-image-container">
            <img src={litigLeftImage} alt="ligit ai" />
            <div className="ligit-ai-top-right-card">
              <div className="ligit-ai-file-container">
                <img src={fileIcon} alt="no file" />
                <div className="ligit-ai-top-right-card-text ">Motion for Detention</div>
                <Button className="ligit-ai-summarize-button button-disabled">
                  <img src={eyeIcon} alt="" /> <span>Summarize</span>
                </Button>
              </div>
            </div>

            {showGeneratingMotion && (
              <div className="litig-ai-left-center-card">
                <div className="litig-ai-data-driven-container">
                  <div className="litig-ai-data-driven-header-container">
                    <h4>Generating Your Motion</h4>
                    <img src={closeIcon} alt="" className="litig-ai-close-icon" onClick={() => setShowGeneratingMotion(true)} />
                  </div>
                  {litigMotionData?.map((data, index) => {
                    return (
                      <div className={`litig-ai-data-driven`} key={index}>
                        <img src={data.image} alt="logo" className="litig-motion-icons" />
                        <span>{data.text}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {showMotionPrompt && (
              <div className="litig-ai-bottom-right-card">
                <div className="litig-ai-bottom-right-card-heading">
                  <h4>Why are you filing this motion?</h4>
                  <img src={closeIcon} alt="" className="litig-ai-close-icon" onClick={() => setShowMotionPrompt(true)} />
                </div>
                <div className="litig-ai-bottom-right-card-body">
                  <textarea
                    name="motionPrompt"
                    disabled={true}
                    value={motionPrompt}
                    onChange={(e) => setMotionPrompt(e.target.value)}
                    placeholder="Describe motion's purpose"
                  />
                  <Button className="button--full-width button-disabled">Continue</Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
  );
};

export default LitigAI;

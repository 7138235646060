import React from 'react';
import './FederalFamily.css';

const FederalFamily = () => {
  return (
    <section className="federal-family-section">
      <div className="federal-family-title-section">
        <h1 className="federal-family-heading">⚖️ Federal Family of Products: Tailored Tools for Legal Professionals</h1>
        <p className="federal-family-text">
          Designed for attorneys, law firms, and federal legal practitioners, these tools drive efficiency, reduce
          repetitive tasks, and deliver impactful results.
        </p>
      </div>
    </section>
  );
};

export default FederalFamily;

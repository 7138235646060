import React from 'react';
import './PricingPlans.css';
import checkIcon from '@assets/images/icons/check-rounded-green.svg';
import rightGreenArrow from '@assets/images/icons/arrow-right-green.svg';
import Button from '@shared/Button/Button';
import { Link } from 'react-router-dom';

const PricingPlans = ({ title, description, price, priceText, buttonText, features, showIncludedHeading, link }) => {
  return (
    <div className="pricing-plan-main-container">
      <div className="pricing-plan-header-section">
        <div className="pricing-plan-title-section">
          <span>Pricing plans</span>
          <h1 className="pricing-plan-title">Get the help you need to work smarter</h1>
        </div>
        <p>Practice law more efficiently, better serve your clients, and grow your practice.</p>
      </div>

      <div className="pricing-plan-price-card-section">
        <div className="pricing-plan-card">
          <div className="pricing-plan-left-side">
            <h3>{title}</h3>
            <p className="pricing-plan-left-side-text">{description}</p>
            {price ? (
              <p className="pricing-plan-price-text">
                ${price} <span className="month-text">/mo</span>
              </p>
            ) : (
              <p className="pricing-plan-text">{priceText}</p>
            )}
            <Link to={link}>
              <Button className="button--primary book-demo-button">{buttonText}</Button>
            </Link>
          </div>

          <div className="pricing-plan-right-side">
            {showIncludedHeading && <h4 className="included-heading">What's included:</h4>}
            {features.map((text, index) => {
              return (
                <li key={index} className="text-points-content">
                  <img src={checkIcon} alt="" />
                  <p>
                    {text.includes('Standard Plan') ? (
                      <>
                        All <Link to='/pricing-cards' className="highlight-standard-plan">Standard Plan</Link> Features
                      </>
                    ) : (
                      text
                    )}
                  </p>
                </li>
              );
            })}
          </div>
        </div>
      </div>

      <div className="view-all-plans-section">
        <Link className='firm-links' to='/pricing-cards'>
          <span>View All Plans</span> <img src={rightGreenArrow} alt="" />
        </Link>
      </div>
    </div>
  );
};

export default PricingPlans;

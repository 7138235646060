import leftArrow from '@assets/images/icons/arrow-left-black-icon.svg';


export const chapterAIDataArray = [
    {
        image: leftArrow,
        text: 'AI-Driven Document Generation',
    },
    {
        image: leftArrow,
        text: 'Lifecycle-Based Bankruptcy Management',
    },
    {
        image: leftArrow,
        text: 'Dynamic Debt Assessment',
    },
    {
        image: leftArrow,
        text: 'Secure E-Signature Integration',
    },
    {
        image: leftArrow,
        text: 'Data Security & Encryption',
    },
    {
        image: leftArrow,
        text: 'Scalable for Individuals, Attorneys, & Businesses',
    },
    {
        image: leftArrow,
        text: 'Automated Compliance Checks',
    },
    {
        image: leftArrow,
        text: 'Collaboration Tools for Teams',
    },
];
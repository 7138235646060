import React from 'react';
import './BookADemo.css';
import Nav from '@shared/Nav/Nav';
import Button from '@shared/Button/Button';
import { Link } from 'react-router-dom';

const ContactSales = () => {
  return (
    <section className="contact-sales-section-container contact-sales-section-container--height">
      <Nav />
      <div className="contact-sales--main">
        <div className="contact-sales--title">
          <span>Pricing Plans</span>
          <h1>Better serve your clients. Grow your practice.</h1>
        </div>
        <p>Choose the plan that meets your needs.</p>
        <Link to="/book-a-demo">
          <Button className="button--primary contact-sales--button">Contact Sales</Button>
        </Link>
      </div>
    </section>
  );
};

export default ContactSales;

import checkMarkIcon from '@assets/images/icons/blue-check-line.svg';
import loopIcon from '@assets/images/icons/loop-left-line.png';
import arrowRightBlackIcon from '@assets/images/icons/right-arrow-gray.svg';
import leftArrow from '@assets/images/icons/arrow-left-blue-icon.svg';


export const litigAIDataArray = [
    {
        image: leftArrow,
        text: 'AI-Workflows for Federal Civil Practice',
    },
    {
        image: leftArrow,
        text: 'Instant Case Posture Analysis',
    },
    {
        image: leftArrow,
        text: 'Full Motion & Brief Drafting',
    },
    {
        image: leftArrow,
        text: 'Research Citations & Motion Recommendations',
    },
    {
        image: leftArrow,
        text: 'Discovery Analysis Tools',
    },
    {
        image: leftArrow,
        text: 'Interactive Document Management',
    },
    {
        image: leftArrow,
        text: 'AI Chatbot for Legal Guidance',
    },
    {
        image: leftArrow,
        text: 'Error Detection & Hallucination Prevention',
    },
];

export const litigMotionData = [
    {
        image: checkMarkIcon,
        text: 'User information loaded',
    },
    {
        image: loopIcon,
        text: 'Loading case background',
    },
    {
        image: arrowRightBlackIcon,
        text: 'Loading motion template',
    },
    {
        image: arrowRightBlackIcon,
        text: 'Generating a top caption',
    },
    {
        image: arrowRightBlackIcon,
        text: 'Researching precedents',
    },
    {
        image: arrowRightBlackIcon,
        text: 'Formulating legal arguments',
    },
    {
        image: arrowRightBlackIcon,
        text: 'Inserting references',
    },
];

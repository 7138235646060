import aIImage from "@assets/images/juristAIInsights/ai.png";
import dataSecurityImage from "@assets/images/juristAIInsights/data-security.png";
import workFollowImage from "@assets/images/juristAIInsights/legal-work-flow.png";

export const insightsData = [
  {
    id: 1,
    date: 'March 13, 2025',
    title: 'How AI is Revolutionizing the Legal World:',
    description:
      'Discover how AI is transforming the legal industry and what it means for the future of legal practice. Learn about the latest advancements and how they can benefit your firm.',
    image: aIImage,
    link: '/blog-post/1',
    titleText:
      'Discover how AI is transforming the legal industry and what it means for the future of legal practice. Learn about the latest advancements and how they can benefit your firm.',
    paragraph1: 'The legal industry is evolving faster than ever, and artificial intelligence (AI) is no longer just a futuristic concept- it’s here, reshaping the way legal professionals work every day. AI-powered tools are transforming everything from document review and legal research to case management, making legal work faster, more efficient, and more accurate. At JuristAI.org, we’re leading this shift, providing cutting-edge AI solutions that help lawyers adapt, innovate, and thrive in a digital-first world.\n',
    paragraph2: 'But it’s not just about documents. AI is also revolutionizing legal research, making it possible to sift through thousands of cases, statutes, and legal texts in seconds. This levels the playing field by giving legal professionals instant access to critical information, helping them craft smarter, more tailored case strategies. The days of spending hours in law libraries or scrolling endlessly through databases are fading—AI is making research faster, easier, and more comprehensive than ever.\n At JuristAI.org, we’ve built AI-driven tools that seamlessly integrate into legal workflows, making advanced analytics accessible to busy professionals. Our platform is designed for real-world legal work—no steep learning curves, just practical, powerful AI that gets results. And we’re not stopping there. We’re constantly refining our technology to keep up with the evolving demands of the legal field, ensuring our users stay ahead of the curve.\n',
    paragraph3: 'But AI isn’t just about efficiency- it’s about staying ahead. By identifying patterns, analyzing trends, and predicting legal outcomes, AI helps lawyers anticipate changes in the law and regulatory landscape. This means legal teams can be more proactive, crafting stronger cases and giving clients a competitive edge in an increasingly complex legal environment.\n The rise of AI in the legal world isn’t just a trend- it’s a fundamental shift that’s redefining the profession. Law firms that embrace AI aren’t just working smarter; they’re positioning themselves for long-term success. At JuristAI.org, we believe AI isn’t just a tool- it’s a necessity for the modern legal practice.\n If you’re ready to see how AI can transform your legal work, now’s the time to explore what JuristAI.org has to offer. The future of law is here, let’s embrace it together.',
  },
  {
    id: 2,
    date: 'July 17, 2023',
    title: 'Enhancing Legal Workflows with Atticus',
    description:
      'Explore how Atticus can streamline your legal workflows, improve efficiency, and enhance collaboration among your legal team. Get tips on integrating AI into your daily practice.',
    image: workFollowImage,
    link: '/blog-post/2',
    titleText:
      'Discover how AI is transforming the legal industry and what it means for the future of legal practice. Learn about the latest advancements and how they can benefit your firm.',
    paragraph1:
      'In the rapidly evolving landscape of legal technology, artificial intelligence (AI) is becoming an indispensable tool for law firms and attorneys. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    paragraph2:
      'Accumsan sit amet nulla facilisi morbi tempus iaculis. In dictum non consectetur a erat nam at lectus. Integer enim neque volutpat ac tincidunt vitae. Nunc sed id semper risus in hendrerit gravida rutrum quisque. Semper quis lectus nulla at volutpat diam. Et tortor consequat id porta nibh venenatis cras sed. Phasellus faucibus scelerisque eleifend donec pretium vulputate. Cras pulvinar mattis nunc sed blandit libero. Sit amet volutpat consequat mauris nunc. Habitant morbi tristique senectus et netus. Magna fringilla urna porttitor rhoncus dolor purus non. Aliquam etiam erat velit scelerisque. Duis tristique sollicitudin nibh sit amet commodo nulla. Mi quis hendrerit dolor magna eget est lorem. Vitae justo eget magna fermentum iaculis eu. Nec feugiat in fermentum posuere urna nec tincidunt. Egestas congue quisque egestas diam in. Et molestie ac feugiat sed. Tempor orci eu lobortis elementum. Tortor pretium viverra suspendisse potenti nullam ac.',
    paragraph3:
      'Purus semper eget duis at tellus at urna. Sed enim ut sem viverra aliquet eget. Nulla at volutpat diam ut venenatis tellus in metus. Eu facilisis sed odio morbi quis. Tincidunt arcu non sodales neque sodales. Platea dictumst vestibulum rhoncus est pellentesque. Pretium quam vulputate dignissim suspendisse in est ante in. Risus quis varius quam quisque id diam vel quam. Massa sed elementum tempus egestas. Ultrices in iaculis nunc sed augue lacus. Velit sed ullamcorper morbi tincidunt. Sit amet justo donec enim diam. Tincidunt augue interdum velit euismod in pellentesque. Amet massa vitae tortor condimentum lacinia quis. Elementum pulvinar etiam non quam lacus suspendisse faucibus interdum posuere. Dignissim enim sit amet venenatis urna cursus eget.',
  },
  {
    id: 3,
    date: 'July 17, 2023',
    title: 'Ensuring Data Security in Legal AI Platforms',
    description:
      'Understand the importance of data security in AI legal platforms and how JuristAI ensures the protection of your confidential information with advanced security measures.',
    image: dataSecurityImage,
    link: '/blog-post/3',
    titleText:
      'Discover how AI is transforming the legal industry and what it means for the future of legal practice. Learn about the latest advancements and how they can benefit your firm.',
    paragraph1:
      'In the rapidly evolving landscape of legal technology, artificial intelligence (AI) is becoming an indispensable tool for law firms and attorneys. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    paragraph2:
      'Accumsan sit amet nulla facilisi morbi tempus iaculis. In dictum non consectetur a erat nam at lectus. Integer enim neque volutpat ac tincidunt vitae. Nunc sed id semper risus in hendrerit gravida rutrum quisque. Semper quis lectus nulla at volutpat diam. Et tortor consequat id porta nibh venenatis cras sed. Phasellus faucibus scelerisque eleifend donec pretium vulputate. Cras pulvinar mattis nunc sed blandit libero. Sit amet volutpat consequat mauris nunc. Habitant morbi tristique senectus et netus. Magna fringilla urna porttitor rhoncus dolor purus non. Aliquam etiam erat velit scelerisque. Duis tristique sollicitudin nibh sit amet commodo nulla. Mi quis hendrerit dolor magna eget est lorem. Vitae justo eget magna fermentum iaculis eu. Nec feugiat in fermentum posuere urna nec tincidunt. Egestas congue quisque egestas diam in. Et molestie ac feugiat sed. Tempor orci eu lobortis elementum. Tortor pretium viverra suspendisse potenti nullam ac.',
    paragraph3:
      'Purus semper eget duis at tellus at urna. Sed enim ut sem viverra aliquet eget. Nulla at volutpat diam ut venenatis tellus in metus. Eu facilisis sed odio morbi quis. Tincidunt arcu non sodales neque sodales. Platea dictumst vestibulum rhoncus est pellentesque. Pretium quam vulputate dignissim suspendisse in est ante in. Risus quis varius quam quisque id diam vel quam. Massa sed elementum tempus egestas. Ultrices in iaculis nunc sed augue lacus. Velit sed ullamcorper morbi tincidunt. Sit amet justo donec enim diam. Tincidunt augue interdum velit euismod in pellentesque. Amet massa vitae tortor condimentum lacinia quis. Elementum pulvinar etiam non quam lacus suspendisse faucibus interdum posuere. Dignissim enim sit amet venenatis urna cursus eget.',
  },
  {
    id: 4,
    date: 'July 17, 2023',
    title: 'Ensuring Data Security in Legal AI Platforms',
    description:
      'Understand the importance of data security in AI legal platforms and how JuristAI ensures the protection of your confidential information with advanced security measures.',
    image: dataSecurityImage,
    link: '/blog-post/4',
    titleText:
      'Discover how AI is transforming the legal industry and what it means for the future of legal practice. Learn about the latest advancements and how they can benefit your firm.',
    paragraph1:
      'In the rapidly evolving landscape of legal technology, artificial intelligence (AI) is becoming an indispensable tool for law firms and attorneys. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    paragraph2:
      'Accumsan sit amet nulla facilisi morbi tempus iaculis. In dictum non consectetur a erat nam at lectus. Integer enim neque volutpat ac tincidunt vitae. Nunc sed id semper risus in hendrerit gravida rutrum quisque. Semper quis lectus nulla at volutpat diam. Et tortor consequat id porta nibh venenatis cras sed. Phasellus faucibus scelerisque eleifend donec pretium vulputate. Cras pulvinar mattis nunc sed blandit libero. Sit amet volutpat consequat mauris nunc. Habitant morbi tristique senectus et netus. Magna fringilla urna porttitor rhoncus dolor purus non. Aliquam etiam erat velit scelerisque. Duis tristique sollicitudin nibh sit amet commodo nulla. Mi quis hendrerit dolor magna eget est lorem. Vitae justo eget magna fermentum iaculis eu. Nec feugiat in fermentum posuere urna nec tincidunt. Egestas congue quisque egestas diam in. Et molestie ac feugiat sed. Tempor orci eu lobortis elementum. Tortor pretium viverra suspendisse potenti nullam ac.',
    paragraph3:
      'Purus semper eget duis at tellus at urna. Sed enim ut sem viverra aliquet eget. Nulla at volutpat diam ut venenatis tellus in metus. Eu facilisis sed odio morbi quis. Tincidunt arcu non sodales neque sodales. Platea dictumst vestibulum rhoncus est pellentesque. Pretium quam vulputate dignissim suspendisse in est ante in. Risus quis varius quam quisque id diam vel quam. Massa sed elementum tempus egestas. Ultrices in iaculis nunc sed augue lacus. Velit sed ullamcorper morbi tincidunt. Sit amet justo donec enim diam. Tincidunt augue interdum velit euismod in pellentesque. Amet massa vitae tortor condimentum lacinia quis. Elementum pulvinar etiam non quam lacus suspendisse faucibus interdum posuere. Dignissim enim sit amet venenatis urna cursus eget.',
  },
  {
    id: 5,
    date: 'July 17, 2023',
    title: 'Enhancing Legal Workflows with Atticus',
    description:
      'Explore how Atticus can streamline your legal workflows, improve efficiency, and enhance collaboration among your legal team. Get tips on integrating AI into your daily practice.',
    image: workFollowImage,
    link: '/blog-post/5',
    titleText:
      'Discover how AI is transforming the legal industry and what it means for the future of legal practice. Learn about the latest advancements and how they can benefit your firm.',
    paragraph1:
      'In the rapidly evolving landscape of legal technology, artificial intelligence (AI) is becoming an indispensable tool for law firms and attorneys. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    paragraph2:
      'Accumsan sit amet nulla facilisi morbi tempus iaculis. In dictum non consectetur a erat nam at lectus. Integer enim neque volutpat ac tincidunt vitae. Nunc sed id semper risus in hendrerit gravida rutrum quisque. Semper quis lectus nulla at volutpat diam. Et tortor consequat id porta nibh venenatis cras sed. Phasellus faucibus scelerisque eleifend donec pretium vulputate. Cras pulvinar mattis nunc sed blandit libero. Sit amet volutpat consequat mauris nunc. Habitant morbi tristique senectus et netus. Magna fringilla urna porttitor rhoncus dolor purus non. Aliquam etiam erat velit scelerisque. Duis tristique sollicitudin nibh sit amet commodo nulla. Mi quis hendrerit dolor magna eget est lorem. Vitae justo eget magna fermentum iaculis eu. Nec feugiat in fermentum posuere urna nec tincidunt. Egestas congue quisque egestas diam in. Et molestie ac feugiat sed. Tempor orci eu lobortis elementum. Tortor pretium viverra suspendisse potenti nullam ac.',
    paragraph3:
      'Purus semper eget duis at tellus at urna. Sed enim ut sem viverra aliquet eget. Nulla at volutpat diam ut venenatis tellus in metus. Eu facilisis sed odio morbi quis. Tincidunt arcu non sodales neque sodales. Platea dictumst vestibulum rhoncus est pellentesque. Pretium quam vulputate dignissim suspendisse in est ante in. Risus quis varius quam quisque id diam vel quam. Massa sed elementum tempus egestas. Ultrices in iaculis nunc sed augue lacus. Velit sed ullamcorper morbi tincidunt. Sit amet justo donec enim diam. Tincidunt augue interdum velit euismod in pellentesque. Amet massa vitae tortor condimentum lacinia quis. Elementum pulvinar etiam non quam lacus suspendisse faucibus interdum posuere. Dignissim enim sit amet venenatis urna cursus eget.',
  },
  {
    id: 6,
    date: 'July 17, 2023',
    title: 'The Future of Legal Practice with AI',
    description:
      'Discover how AI is transforming the legal industry and what it means for the future of legal practice. Learn about the latest advancements and how they can benefit your firm.',
    image: aIImage,
    link: '/blog-post/6',
    titleText:
      'Discover how AI is transforming the legal industry and what it means for the future of legal practice. Learn about the latest advancements and how they can benefit your firm.',
    paragraph1:
      'In the rapidly evolving landscape of legal technology, artificial intelligence (AI) is becoming an indispensable tool for law firms and attorneys. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    paragraph2:
      'Accumsan sit amet nulla facilisi morbi tempus iaculis. In dictum non consectetur a erat nam at lectus. Integer enim neque volutpat ac tincidunt vitae. Nunc sed id semper risus in hendrerit gravida rutrum quisque. Semper quis lectus nulla at volutpat diam. Et tortor consequat id porta nibh venenatis cras sed. Phasellus faucibus scelerisque eleifend donec pretium vulputate. Cras pulvinar mattis nunc sed blandit libero. Sit amet volutpat consequat mauris nunc. Habitant morbi tristique senectus et netus. Magna fringilla urna porttitor rhoncus dolor purus non. Aliquam etiam erat velit scelerisque. Duis tristique sollicitudin nibh sit amet commodo nulla. Mi quis hendrerit dolor magna eget est lorem. Vitae justo eget magna fermentum iaculis eu. Nec feugiat in fermentum posuere urna nec tincidunt. Egestas congue quisque egestas diam in. Et molestie ac feugiat sed. Tempor orci eu lobortis elementum. Tortor pretium viverra suspendisse potenti nullam ac.',
    paragraph3:
      'Purus semper eget duis at tellus at urna. Sed enim ut sem viverra aliquet eget. Nulla at volutpat diam ut venenatis tellus in metus. Eu facilisis sed odio morbi quis. Tincidunt arcu non sodales neque sodales. Platea dictumst vestibulum rhoncus est pellentesque. Pretium quam vulputate dignissim suspendisse in est ante in. Risus quis varius quam quisque id diam vel quam. Massa sed elementum tempus egestas. Ultrices in iaculis nunc sed augue lacus. Velit sed ullamcorper morbi tincidunt. Sit amet justo donec enim diam. Tincidunt augue interdum velit euismod in pellentesque. Amet massa vitae tortor condimentum lacinia quis. Elementum pulvinar etiam non quam lacus suspendisse faucibus interdum posuere. Dignissim enim sit amet venenatis urna cursus eget.',
  },
];


export const tableOfContent = [
  'Introduction',
  'The role of AI in Legal Industry',
  'The Imperative of Data Security',
  'Advanced Encryption Protocols',
  'Granular Access Controls',
  'Regulatory Compliance Assurance',
  'Continuous Security Enhancements',
  'The Benefits for Your Firm',
  'Conclusion'
]

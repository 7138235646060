import React, { useState } from "react";
import "./DataApplication.css";
import ContentSwitch from "../ContentSwitch/ContentSwitch";
import checkMarkIcon from "@assets/images/icons/white-check-line.png";
import { featureData } from "./featureData";

const DataApplicationSection = ({ refValue }) => {
  const [selectedKeyFeatures, setSelectedKeyFeatures] = useState(featureData[0].keyFeatures);

  const handleAccordionSelect = (index) => {
    setSelectedKeyFeatures(featureData[index].keyFeatures);
  };

  return (
    <div className="data-application-section" ref={refValue}>
      <h2>Data & Application Security</h2>
      <p className="data-application-section-subheading">Your data's security is our top priority—and embedded into all our processes, which we work continually to keep improving.</p>
      <div className="data-application-section-accordions-container">
        <ContentSwitch 
          data={featureData}
          renderHeader={(item) => <div className="accordion-section-header">{item.title}</div>}
          renderBody={(item) => <p>{item.description}</p>}
          onSelect={handleAccordionSelect}
        />
        <div className="key-features-container">
          {selectedKeyFeatures?.map((feature, index) => (
            <div key={index} className="key-features-sub-container">
              <img src={checkMarkIcon} alt="no check mark" />
              <span> {feature} </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DataApplicationSection;

import checkMarkIcon from '@assets/images/icons/check-line.png';
import loopIcon from '@assets/images/icons/loop-left-line.png';
import arrowRightBlackIcon from '@assets/images/icons/right-arrow-gray.svg';
import leftArrow from '@assets/images/icons/arrow-left-green-icon.svg';


export const atticusAIDataArray = [
    {
        image: leftArrow,
        text: 'Web Dashboard',
    },
    {
        image: leftArrow,
        text: 'Document Management',
    },
    {
        image: leftArrow,
        text: 'AI-Powered Motion & Brief Drafting',
    },
    {
        image: leftArrow,
        text: 'Chatbot for Legal Guidance',
    },
    {
        image: leftArrow,
        text: 'Personalized Motion Recommendations',
    },
    {
        image: leftArrow,
        text: 'Comprehensive Legal Analysis',
    },
    {
        image: leftArrow,
        text: 'Seamless Collaboration Tools',
    },
    {
        image: leftArrow,
        text: 'And much more',
    },
];

export const atticusMotionData = [
    {
        image: checkMarkIcon,
        text: 'User information loaded',
    },
    {
        image: loopIcon,
        text: 'Loading case background',
    },
    {
        image: arrowRightBlackIcon,
        text: 'Loading motion template',
    },
    {
        image: arrowRightBlackIcon,
        text: 'Generating a top caption',
    },
    {
        image: arrowRightBlackIcon,
        text: 'Researching precedents',
    },
    {
        image: arrowRightBlackIcon,
        text: 'Formulating legal arguments',
    },
    {
        image: arrowRightBlackIcon,
        text: 'Inserting references',
    },
];

import axios from 'axios';
import { getAuthHeaders, graphQlUrl } from '../utilities';

export const getUser = async (email) => {
  try {
    const query = `
      query MyQuery {
        user(email: "${email}") {
          assignedCases
          userId
          organizationId
          username
          email
          firstName
          lastName
          createdDate
          legalTeamIds
        }
      }
    `;

    const encodedQuery = encodeURIComponent(query); // Encode the query for safe URL usage
    const URL = `${graphQlUrl}${encodedQuery}`;
    const response = await axios.get(URL);
    return {
      success: true,
      data: response.data.data
    }; // Return the user data
  } catch (error) {
    console.error('Error getting user:', error);
    return { success: false, message: error.message };
  }
};

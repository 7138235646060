import React, { useState } from "react";
import Button from "@shared/Button/Button";
import TextField from "@shared/TextField/TextField";
import LoaderSpinner from "@shared/LoaderSpinner/LoaderSpinner";
import './ResetPassword.css';
import { useParams } from "react-router-dom";
import { resetPassword } from "@lib/apis/password-reset";
import { Link } from "react-router-dom";
import { success, error } from '@lib/toast';
import JusristAiLogo from '@assets/images/juristAIInsights/jurist-logo-full.png';

const ResetPassword = () => {
  const [formData, setFormData] = useState({
    password: "",
    passwordConfirm: ""
  });
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [showButton, setShowButton] = useState(true);
  const token = useParams().token;
  const uid = useParams().uid;
  const passwordValidation = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{6,}$/;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

    if (e.target.name === "email" && e.target.value) {
      setResponseMessage("");
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();



    if (!formData.password) {
      setResponseMessage("Please provide a password.");
      return;
    }
    if (!passwordValidation.test(formData.password)) {
      setResponseMessage("Password must be at least 6 characters, include an uppercase, lowercase, and a special character.");
      return;
    }
    if (formData.passwordConfirm !== formData.password) {
      setResponseMessage("Passwords do not match.");
      return;
    }

    setLoading(true);
    const response = await resetPassword(formData.password, formData.passwordConfirm, uid, token);

    if (response.success) {
      setResponseMessage("Password successfully reset. Please log in.");
      success("Password successfully reset. Redirecting to log in page.");
      setTimeout(() => {
        window.location.href = '/login';
      }, 3000);
    } else {
      setResponseMessage("Error resetting password. Please try again.");
      error("Error resetting password. Please try again.");
    }

    setLoading(false);
    setShowButton(false);
  };

  return (
    <main className="reset-password-page">

      <section className="rp-left">
        <img src={JusristAiLogo} alt="jurist-ai-logo" className="auth-logo" />
        <h2 className="reset-heading">We are revolutionizing the law.</h2>
      </section>

      <section className="rp-right">
        <div className="rp-form-container">
          <form>
            <h1>Reset Password</h1>
            <TextField
              placeholder="Password"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="input-pass"
            />
            <TextField
              placeholder="Confirm Password"
              type="password"
              name="passwordConfirm"
              value={formData.passwordConfirm}
              onChange={handleChange}
            />
            {showButton ? (
              <Button type="submit" onClick={handleClick} className="button--primary rp-page-button" disabled={loading || !passwordValidation}>
                {loading
                  ? <LoaderSpinner messages={''} hideText={false} color='var(--color-black)' width={20} />
                  : 'Reset Password'
                }
              </Button>
            ) : (
              <Link to="/login">
                <Button className="button--primary log-in">Reset</Button>
              </Link>
            )}

            <p className={`rp-response-message text-center ${responseMessage && !responseMessage.includes("successfully") ? "reset-password-error" : "reset-password-success"}`}>
              {responseMessage}
            </p>

          </form>
        </div>
      </section>
    </main>
  );
};

export default ResetPassword;
import React from "react";
import "./SoftwareSuites.css";
import SoftwareImage from "@assets/images/softwareSuites/softwaresuites.png";
import Hero from "@assets/images/softwareSuites/hero-image.png";
import comingSoon from "@assets/images/softwareSuites/coming-soon.png";
import ContentSwitch from "../ContentSwitch/ContentSwitch";
import FeatureCarousel from "./Carousel/Carousel";
import { Link } from "react-router-dom";
import Button from "@shared/Button/Button";
import arrowRight from "@assets/images/icons/arrow-right-black.png";

const SoftwareSuites = ({ refValue }) => {
  const suites = [
    {
      title: "Federal Criminal",
      description: "Atticus AI provides a comprehensive workflow for Federal defense attorneys including discovery analysis and full motion drafting.",
      image: SoftwareImage,
      link: 'https://atticusai.org',
    },
    {
      title: "Start-Up Documentation",
      description: "SeriesAI provides start-up documentation from incorporation until exit or IPO.",
      image: Hero,
      link: 'https://seriesai.co',
    },
    {
      title: "Family Law",
      description: "Coming Soon!",
      image: comingSoon,
      link: '#',
    },
    {
      title: "Civil Litigation",
      description: "Coming Soon!",
      image: comingSoon,
      link: 'https://litig.com',
    },
    {
      title: "Intellectual Property",
      description: "Coming Soon!",
      image: comingSoon,
      link: '#',
    },
  ];

  return (
    <section className="software-suites-section">
      <h2 className="software-suites-title">Our Software Suites</h2>

      <ContentSwitch
        data={suites}
        renderHeader={(suite, index, isActive) => (
          <div className={`accordion-section-header ${isActive ? "active" : ""}`} >
            {suite.title}
          </div>
        )}
        renderBody={(item) => (
          <div>
            <p>{item.description}</p>
            {item.link && (
              <Link to={item.link !== "#" ? item.link : "#"} target={item.link !== "#" ? "_blank" : ""}>
                <Button className={`button--primary ${item.link === "#" ? "button-disabled" : ""}`} type="submit" disabled={item.link === "#"}>
                  Visit Website <img src={arrowRight} alt="Atticus Recommends" />
                </Button>
              </Link>
            )}
          </div>
        )}
        isImage
      />
      <h2 className="software-suites-sub-title" ref={refValue}>
        Unlock the Power of AI for Legal Success with JuristAI
      </h2>
      <p className="software-suites-description">
        Embrace the Future of Legal Practice with JuristAI's Revolutionary AI-powered Solutions. From streamlining case management to enhancing research capabilities, our suite of SaaS products is meticulously designed to empower attorneys like never before.
      </p>

      <FeatureCarousel />
    </section>
  );
};

export default SoftwareSuites;

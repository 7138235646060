import React, { useState, useEffect } from 'react';
import './ChangePlan.css';
import Button from '@shared/Button/Button';
import { changePlanData } from './changePlanData';
import { getPricingStrategy } from '@lib/apis/resources/pricingStrategy';
import { toSentenceCase, timeStampToMDY } from '@lib/utils/strings';
import { getSubscriptionsByUserId, getOrSetSubscription } from '@lib/apis/resources/subscription';
import { createStripeCheckoutLink } from "@lib/apis/stripe";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';


const ChangePlan = ({currentPlan, organizationId}) => {
  const [plans, setPlans] = useState([]);
  const userId = useSelector((state) => state.user.cognito_user_id);
  const userEmail = useSelector((state) => state.user.email);


  useEffect(() => {
    const fetchPricingStrategy = async () => {
      const response = await getPricingStrategy();
      if (response.success) {
        console.log("pricing strategy response: ", response);
        const pricingStrategy = response.data.pricingStrategy;
        const { appId, pricingStrategyId, strategyType, tiers } = pricingStrategy;
        // gets checkout page link
        // need to add it to the tier object

        // Send request to get checkout url for each tier and then add it to the the tier object
        tiers.forEach(async (tier) => {
          const DAYS = 30; // Set the number of days for the subscription
          const subscription = await getOrSetSubscription(userId, appId, userEmail);
          const stripeCheckoutParams = {
            tier,
            userId,
            pricingStrategyId, 
            accessLevel: "admin", //todo check if this is correct
            subsId: subscription.subsId,
            organizationId: organizationId,
            appId,
            days: DAYS,
            pricingTier: tier.tier,
            amountCharged: tier.parameters.costPerUser,
            strategyType,
            tiers,
          }
          const stripeCheckoutlink = await createStripeCheckoutLink(stripeCheckoutParams);
          tier.checkoutLink = stripeCheckoutlink;
        });

        setPlans(tiers);
      }
    }
    if (organizationId) {
      fetchPricingStrategy();
    }
  }, [organizationId]);

  return (
    <>
       <div className="container-section">
       { currentPlan && (<div className="current-plan-container">
          {plans
            .filter((plan) => plan?.tier === currentPlan?.pricingTier)
            .map((plan) => (
              <div className="current-plan-section" key={`${currentPlan.pricingTier}-current-choice`}>
                <p>Current Plan: {`${toSentenceCase(currentPlan.pricingTier)}`}</p>
                <div className="current-plan-content">
                  <span className="key">Billing Amount</span>
                  <span className="value">{`$${currentPlan.AmountCharged}/mo`}</span>
                </div>
                <hr className="border-bottom" />
                <div className="current-plan-content">
                  <span className="key">Next Bill Date</span>
                  <span className="value">{`${timeStampToMDY(currentPlan.endDate)}`}</span>
                </div>
              </div>
            ))}
        </div>)}

        <div className="other-plans-container">
          <p className="other-plans-title"> {currentPlan ? "Other Plans" : "Plans"}</p>
          {plans
            .filter((plan) => !plan.isCurrent && plan.checkoutLink !== null)
            .map((otherPlan, i) => (
              <div className="plans-card-section" key={`${otherPlan.name}${i}`}>
                <div className="plans-card-content">
                  <p>{toSentenceCase(otherPlan?.tier)}</p>
                  <p>{`$${otherPlan?.parameters.costPerUser}/mo`}</p>
                </div>
                <div className="plan-buttons">
                    <Button className="button--transparent switch-plan-button">
                      <Link to={otherPlan.checkoutLink} style={{ textDecoration: 'none', color: 'inherit' }}>
                      Switch to this plan
                    </Link>

                      </Button>
                  <Button className="view-feature-button">View Features</Button>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default ChangePlan;
import React from 'react';
import './ProductHeroSection.css';

const ProductHeroSection = () => {
  return (
    <main className="main-products-hero-section">
      <div className="products-hero-section-container">
        <span className="products-hero-section-badge">Products</span>
        <h1 className="products-hero-section-title">Empowering Legal Excellence with AI-Driven Solutions</h1>
        
        <p className="products-hero-section-text">
          Innovative AI tools tailored for Startups, Federal Practices, and Specialized Legal Fields.
        </p>
      </div>
    </main>
  );
};

export default ProductHeroSection;

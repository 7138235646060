import React from 'react';
import './Blog.css';
import Nav from '@shared/Nav/Nav';
import aiImage from '@assets/images/juristAIInsights/ai.png';
import { Link } from 'react-router-dom';
import Footer from '@shared/Footer/Footer';
import rightArrowGreen from '@assets/images/icons/arrow-right-green-gradient.svg';
import Card from '@shared/Card/Card';
import { insightsData } from '../Home/JuristAIInsights/juristAIInsightsData';

const Blog = () => {
  return (
    <section className="blog-page-main-section">
      <Nav />

      <div className="blog-page-header-section">
        <div className="blog-page-title-container">
          <span>Blog</span>
          <h1 className="blog-page-title">Transforming Legal Services</h1>
          <div className="blog-page-title-text">
            <h1 className="blog-page-title">with Generative AI</h1>
            <p>
              Explore the future of legal practice with JuristAI. Stay informed on the latest developments, trends, and
              breakthroughs in AI-powered legal services. Discover how JuristAI is transforming the legal landscape with
              cutting-edge technology and expert insights.
            </p>
          </div>
        </div>
      </div>

      <div className="blog-page-feature-section">
        <div className="blog-page-feature-card">
          <div className="blog-feature-image-left">
            <img src={aiImage} alt="AI Image" />
          </div>

          <div className="blog-feature-content-right">
            <span>Featured</span>
            <div className="blog-page-feature-card--title">
              <h3>The Future of Legal Practice with AI</h3>
              <p>
                Discover how AI is transforming the legal industry and what it means for the future of legal practice.
                Learn about the latest advancements and how they can benefit your firm.
              </p>
            </div>

            <Link to="#" className="button-disabled read-more--button">
              Read More
              <img src={rightArrowGreen} alt="" />
            </Link>
          </div>
        </div>
      </div>

      <div className="blog-page-all-posts">
        <h2>All Posts</h2>
        <div className="insights-grid">
          <Card data={insightsData} />
        </div>
      </div>

      <Footer />
    </section>
  );
};

export default Blog;

import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import LogIn from '@pages/Auth/LogIn/LogIn';
import Home from '@pages/Home/Home';
import Products from '@pages/Products/Products';
import SignUp from '@pages/Auth/SignUp/SignUp';
import DashboardLayout from '@pages/Dashboard/JuristAi/Layout/Layout';
import WelcomeJurist from '@pages/Dashboard/JuristAi/Jurist/WelcomeJurist/WelcomeJurist';
import LogOut from '@pages/Auth/LogOut/LogOut';
import Layout from '@pages/Home/_Layout/Layout';
import { useSelector } from 'react-redux';
import ConfirmAccount from '@pages/Auth/ConfirmAccount/ConfirmAccount';
import ManageSubscription from '@pages/Dashboard/JuristAi/Jurist/ManageSubscription/ManageSubscription';
import ManageOrganization from '@pages/Dashboard/JuristAi/Jurist/ManageOrganization/ManageOrganization/ManageOrganization';
import AccountOption from '@pages/Dashboard/JuristAi/Jurist/AccountOption/AccountOption';
import TestPage from '@pages/Home/Test/Test';
import ContactSales from '@pages/Home/BookADemo/ContactSales';
import BookADemo from '@pages/Home/BookADemo/BookADemo';
import Blog from '@pages/Blog/Blog.jsx';
import BlogPost from '@pages/Blog/BlogPost/BlogPost';
import PrivateAttorney from '@pages/Home/Pricing/Firms/PrivateAttorney';
import SmallFirm from '@pages/Home/Pricing/Firms/SmallFirms';
import BigFirm from '@pages/Home/Pricing/Firms/BigFirms';
import Pricing from '@pages/Home/Pricing/Firms/Pricing';
import ForgotPassword from '@pages/Auth/Password/ForgotPassword/ForgotPassword';
import ResetPassword from '@pages/Auth/Password/ResetPassword/ResetPassword';

function AllRouter() {
  const isAuthenticated = useSelector((state) => state?.user);
  const PublicRoute = ({ children }) => {
    if (isAuthenticated) {
      return <Navigate to="/dashboard" />;
    }
    return children;
  };
  return (
    <>
      <Routes>
        <Route index element={<Home />} />

        <Route path="/" element={<Layout />}>
          <Route path="logout" element={<LogOut />} />
          <Route path="register/confirm-account/:token" element={<ConfirmAccount />} />
        </Route>

        <Route path="forgot-password"
          element={
            <ForgotPassword />
          }
        />

        <Route
          path="/login"
          element={
            <PublicRoute>
              <LogIn />
            </PublicRoute>
          }
        />
        <Route
          path="/signup"
          element={
            <PublicRoute>
              <SignUp />
            </PublicRoute>
          }
        />

        <Route
          path="password-reset/confirm-account/:uid/:token"
          element={
            <PublicRoute>
              <ResetPassword />
            </PublicRoute>
          }
        />


        <Route path='/pricing-cards' element={<Pricing />} />
        <Route path='/private-attorney' element={<PrivateAttorney />} />
        <Route path='/small-firm' element={<SmallFirm />} />
        <Route path='/big-firm' element={<BigFirm />} />


        <Route path='/contact' element={<ContactSales />} />
        <Route path='/book-a-demo' element={<BookADemo />} />

        <Route path='/blog' element={<Blog />} />
        <Route path='/blog-post/:id' element={<BlogPost />} />

        <Route path="/products" element={<Products />} />
        <Route path="/dev_test" element={<TestPage />} />

        <Route path="/dashboard/" element={<DashboardLayout />}>
          <Route index element={<WelcomeJurist />} />
          <Route path="subscriptions" element={<ManageSubscription />} />
          <Route path="organization" element={<ManageOrganization />} />
          <Route path="account" element={<AccountOption />} />
        </Route>
      </Routes>
    </>
  );
}

export default AllRouter;

import React, { useState } from 'react';
import './LogIn.css';
import JusristAiLogo from '@assets/images/juristAIInsights/jurist-logo-full.png';
import { ReactComponent as GoogleLogo } from '@assets/images/icons/google-logo.svg';
import { ReactComponent as AppleLogo } from '@assets/images/icons/apple-logo.svg';
import TextField from '@shared/TextField/TextField';
import Button from '@shared/Button/Button';
import { Link, useNavigate } from 'react-router-dom';
import { error as ToastError } from '@lib/toast';
import { useDispatch } from 'react-redux';
import { logInEmail } from '@state/actions/authActions';


const LogIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ email: '', password: '' });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const attemptLogin = async (e) => {
    e.preventDefault();
    const response = await dispatch(logInEmail(formData.email, formData.password));
    if (response.success) {
      navigate('/dashboard');
    } else {
      console.log('error');
      console.log(response);
      const errorMessage = response.message || 'Error loggin in.';
      ToastError(errorMessage);
    }
  };

  return (
    <main className="auth-page login-page">
      <section className="auth-left">
        <img src={JusristAiLogo} alt="jurist-ai-logo" className="auth-logo" />
        <h2>We are revolutionizing the law.</h2>
      </section>
      <section className="auth-right">
        <div className="auth-form-container">
          <form onSubmit={attemptLogin}>
            <h1>Welcome Back!</h1>
            <p>Just a few quick details and you're in.</p>
            <TextField
              placeholder="Email address"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            <TextField
              placeholder="Password"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
            <Link to="/forgot-password" className="forgot-password-link">
              Forgot password?
            </Link>
            <Button type="submit" className="button--primary login-page-button">
              LogIn
            </Button>
            <p>or sign in with</p>
            <button className="third-party-auth-button">
              <GoogleLogo />
              <p>Continue with Google</p>
            </button>
            <button className="third-party-auth-button">
              <AppleLogo />
              <p>Continue with Apple</p>
            </button>
            <p>
              Don't have an account? <Link to="/signup">Sign up</Link>
            </p>
          </form>
        </div>
      </section>
    </main>
  );
};

export default LogIn;

import React, { useState } from 'react';
import './ChapterAI.css';
import PlayIcon from '@assets/images/icons/play-icon.svg';
import chapterleftImage from '@assets/images/productImages/chapter-left-img.png';
import chapterAI from '@assets/images/logos/chapter-ai-logo.svg';
import checkListIcon from '@assets/images/icons/check-list-icon.svg';
import Button from '@shared/Button/Button';
import { chapterAIDataArray } from './chapterData';

const ChapterAI = () => {
  return (
    <section className="chapter-ai-section">
      <div className="chapter-ai-container">
        <div className="chapter-ai-content">
          <div className="chapter-ai-left-image-container">
            <img className="chapter-ai-left-image" src={chapterleftImage} alt="atticus" />
            <div className="chapter-ai-top-right-card">
              <div className="chapter-ai-top-right-card-content">
                <h3>Chapter 7 Bankruptcy is the best choice for your business</h3>
                <p>This option involves liquidating non-exempt assets to pay creditors and then closing the business. </p>
              </div>
              <Button className="button--full-width chapter-filing-button">Start Chapter 7 Filing</Button>
            </div>

            <div className="chapter-ai-bottom-right-card">
              <div className="bottom-right-card-checklist-icon">
                <img src={checkListIcon} alt="checkList Icon" />
              </div>
              <div className="chapter-ai-bottom-right-card-content">
                <h4>Your Petition Has Been Submitted</h4>
                <p>Your petition has been successfully filed with the court. </p>
              </div>
            </div>
          </div>

          <div className="chapter-ai-right-content">
            <div className="chapter-ai-icon-section">
              <img src={chapterAI} alt="chapter ai" className="chapter-ai-text-logo" />
            </div>
            <div className="chapter-ai-title-section">ChapterAI: Filing for Bankruptcy Made Simple and Accurate with AI</div>
            <div className="chapter-ai-paragrapgh-content">
              ChapterAI is the bankruptcy software designed to cater to individuals, attorneys, and businesses. With intelligent tools tailored to streamline
              documentation, manage compliance, and guide users through complex legal processes, ChapterAI ensures that every filing meets the highest standards
              of accuracy and efficiency.
            </div>
            <div className="chapter-ai-features-data">
              {chapterAIDataArray &&
                chapterAIDataArray.map((feature, index) => (
                  <div key={index} className="feature-item">
                    <img src={feature.image} alt="left arrow" className="feature-icon" />
                    <span className="feature-text">{feature.text}</span>
                  </div>
                ))}
            </div>
            <div className="chapter-ai-button-section">
              <Button>
                <img src={PlayIcon} alt="play icon" />
                Watch Video
              </Button>
              <Button>Visit ChapterAI</Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChapterAI;

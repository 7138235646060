import React from 'react';
import './Products.css';
import Nav from '@shared/Nav/Nav';
import ProductHeroSection from './ProductHeroSection/ProductHeroSection';
import SeriesAI from './SeriesAI/SeriesAI';
import FederalFamily from './FederalFamily/FederalFamily';
import AtticusAI from './AtticusAI/AtticusAI';
import LitigAI from './LitigAI/LitigAI';
import ChapterAI from './ChapterAI/ChapterAI';
import Footer from '@shared/Footer/Footer';

const Products = () => {
  return (
    <>
      <div className="hero-section-product-page">
        <Nav />
        <ProductHeroSection />
      </div>

      <SeriesAI />
      <FederalFamily />
      <AtticusAI />
      <LitigAI />
      <ChapterAI />
      <div className="product-page-footer-bg-color">
        <Footer />
      </div>
    </>
  );
};

export default Products;

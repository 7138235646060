import React from 'react';
import NavBar from '@shared/Nav/Nav';
import PricingPlans from '../Shared/PricingPlans/PricingPlans';

const bigFirmsPoints = [
  'All Premium Plan Features',
  'Dedicated Account Manager',
  'Personalized Training Sessions',
  'Tailored AI Models for Your Practice',
  'Early Access to New Features',
  'Unlimited Case Citations',
];

const BigFirm = () => {
  return (
    <div className='firms-container'>
      <NavBar />
      <PricingPlans
        title="Big Firms"
        description="Get tailored AI solutions and premium support for large firms."
        priceText="Contact Sales for Pricing"
        buttonText="Contact Sales"
        features={bigFirmsPoints}
        showIncludedHeading={true}
        link='/contact'
      />
    </div>
  );
};

export default BigFirm;

import React, { useState } from 'react';
import './SignUp.css';
import JusristAiLogo from '@assets/images/juristAIInsights/jurist-logo-full.png';
import TextField from '@shared/TextField/TextField';
import Button from '@shared/Button/Button';
import { ReactComponent as GoogleLogo } from '@assets/images/icons/google-logo.svg';
import { ReactComponent as AppleLogo } from '@assets/images/icons/apple-logo.svg';
import { Link } from 'react-router-dom';
import { registerEmail } from '@lib/apis/auth';
import { success as ToastSuccess } from '@lib/toast';


const SignUp = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password1: '',
    password2: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateInputs = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{6,}$/;
    let newErrors = {};

    if (formData.firstName.length < 3) {
      newErrors.firstName = 'First name must be at least 3 characters long.';
    }
    if (formData.lastName.length < 3) {
      newErrors.lastName = 'Last name must be at least 3 characters long.';
    }
    if (!emailRegex.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address.';
    }
    if (!passwordRegex.test(formData.password1)) {
      newErrors.password1 = 'Password must be at least 6 characters, contain a number, an uppercase, a lowercase letter, and a special character.';
    }
    if (formData.password1 !== formData.password2) {
      newErrors.password2 = 'Passwords do not match.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const attemptRegister = async (e) => {
    e.preventDefault();

    if (!validateInputs()) return;

    const response = await registerEmail(
      formData.email,
      formData.password1,
      formData.password2,
      formData.firstName,
      formData.lastName
    );

    if (response.success) {
      ToastSuccess(`Registration successful. Confirmation email sent to ${formData.email}. Please check your email to confirm.`);
    } else {
      setErrors({ general: response?.message || 'Error registering. Please check all fields and try again.' });
    }
  };

  return (
    <main className="auth-page login-page signup-page">
      <section className="auth-left">
        <img src={JusristAiLogo} alt="jurist-ai-logo" className="auth-logo" />
        <h2>We are revolutionizing the law.</h2>
      </section>
      <section className="auth-right">
        <div className="auth-form-container signup-form-container">
          <form onSubmit={attemptRegister}>
            <h1>Join JuristAI!</h1>
            <p>Just a few quick details and you're in.</p>
            <TextField
              placeholder="First Name"
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
            />
            {errors.firstName && <p className="error-message">{errors.firstName}</p>}
            <TextField
              placeholder="Last Name"
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
            />
            {errors.lastName && <p className="error-message">{errors.lastName}</p>}
            <TextField
              placeholder="Email address"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <p className="error-message">{errors.email}</p>}
            <TextField
              placeholder="Password"
              type="password"
              name="password1"
              value={formData.password1}
              onChange={handleChange}
            />
            {errors.password1 && <p className="error-message">{errors.password1}</p>}
            <TextField
              placeholder="Confirm Password"
              type="password"
              name="password2"
              value={formData.password2}
              onChange={handleChange}
            />
            {errors.password2 && <p className="error-message">{errors.password2}</p>}
            {errors.general && <p className="error-message">{errors.general}</p>}
            <p>By clicking create account, I agree to the Terms & Conditions & Privacy Policy.</p>

            <Button type="submit" className="button--primary login-page-button">
              Create Account
            </Button>
            <p>or sign up with</p>
            <button className="third-party-auth-button">
              <GoogleLogo />
              <p>Continue with Google</p>
            </button>
            <button className="third-party-auth-button">
              <AppleLogo />
              <p>Continue with Apple</p>
            </button>
            <p>
              Already have an account? <Link to="/login">Log in</Link>
            </p>
          </form>
        </div>
      </section>
    </main>
  );
};

export default SignUp;

import React, { useState } from 'react';
import './SeriesAI.css';
import Button from '@shared/Button/Button';
import SeriesTextLogo from '@assets/images/logos/series-ai-logo.svg';
import seriesRightImage from '@assets/images/productImages/series-right-img.png';
import PlayIcon from '@assets/images/icons/play-icon.svg';
import fileIcon from '@assets/images/icons/file-with-background.svg';
import generateIcon from '@assets/images/icons/ai-generate-icon.svg';
import dotsIcon from '@assets/images/icons/three-dots-icon.svg';
import closeIcon from '@assets/images/icons/close-icon.svg';
import exclaimation from '@assets/images/icons/exclaimation-gray-icon.svg';
import { generatingDocumentData, seriesAIDataArray } from './seriesData';

const SeriesAI = () => {
  const [activeBar, setActiveBar] = useState(1);

  const handleBarClick = (index) => {
    setActiveBar(index);
  };
  return (
      <section className="series-ai-section-container">
        <div className="series-ai-container">
          <div className="series-ai-left-content">
            <div className="series-ai-icon-section">
              <img src={SeriesTextLogo} alt="series ai text" className="series-ai-text-logo" />
            </div>
            <div className="series-ai-title-section">SeriesAI: Your Startup’s Strategic Partner</div>
            <div className="series-ai-paragrapgh-content">
              Unlock the potential of your startup with SeriesAI, an AI-powered platform built to streamline document
              drafting, cap table management, and business planning. Designed for startups at every stage, SeriesAI
              adapts to your needs—whether you’re incorporating, securing funding, or managing equity distribution. With
              intelligent workflows, dynamic equity tools, and seamless document automation, SeriesAI empowers founders
              and teams to focus on growth while minimizing administrative overhead.
            </div>
            <div className="series-ai-features-data">
              {seriesAIDataArray &&
                seriesAIDataArray.map((feature, index) => (
                  <div key={index} className="feature-item">
                    <img src={feature.image} alt="left arrow" className="feature-icon" />
                    <span className="feature-text">{feature.text}</span>
                  </div>
                ))}
            </div>
            <div className="series-ai-button-section">
              <Button>
                <img src={PlayIcon} alt="play icon" />
                Watch Video
              </Button>
              <Button>Visit SeriesAI</Button>
            </div>
          </div>

          <div className="series-ai-right-image-container">
            <img src={seriesRightImage} alt="series ai " />
            <div className="series-ai-top-right-card">
              <div className="series-ai-file-container">
                <img src={fileIcon} alt="no file" className="top-right-card-file-icon" />
                <div className="top-right-generate-content">
                  <Button className="top-right-card-generate-button button-disabled">
                    <img src={generateIcon} alt="generate icon" /> <span>Generate</span>
                  </Button>
                  <img src={dotsIcon} alt="dots" />
                </div>
              </div>
            </div>

            <div className="series-ai-center-left-card">
              <div className="center-left-card-heading-section">
                <div className="center-left-card-title">
                  <p className="center-left-card-text">Document Drafting Assistant | SAFE</p>
                  <img src={closeIcon} alt="close" />
                </div>
                <div className="progress-container">
                  {[1, 2, 3, 4, 5].map((bar, index) => (
                    <div
                      key={index}
                      className={`progress-bar ${activeBar === bar ? 'active' : ''}`}
                      onClick={() => handleBarClick(bar)}
                    ></div>
                  ))}
                </div>
              </div>
              <div className="center-left-card-document-type">
                <p className="document-type-text">Select Document Type</p>
                <Button className="discount-rate-button">
                  Discount Rate
                  <img src={exclaimation} alt="circle" />
                </Button>
                <Button className="valuation-cap-button">
                  Valuation Cap
                  <img src={exclaimation} alt="circle" />
                </Button>
              </div>
              <Button className="center-left-card-continue-button">Continue</Button>
            </div>

            <div className="series-ai-bottom-righ-card">
              <div className="series-ai-data-driven-container">
                <div className="series-ai-data-driven-header-container">
                  <h4>Generating your document</h4>
                  <img src={closeIcon} alt="close" className="close-icon-series-ai" />
                </div>
                {generatingDocumentData?.map((data, index) => {
                  return (
                    <div className={`series-ai-data-driven`} key={index}>
                      <img src={data.image} alt="logo" />
                      <span>{data.text}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
  );
};

export default SeriesAI;

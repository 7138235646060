import React, { useEffect } from 'react';
import './Layout.css';
import NavBar from '../Shared/Navbar/Navbar';
import { Outlet, useNavigate } from 'react-router-dom';
import AccountDropdown from '@shared/AccountDropdown/AccountDropdown';
import { useSelector } from 'react-redux';
import { error } from '@lib/toast';

const Layout = () => {
  const user = useSelector((state) => state?.user);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
      error('Please log in.');
    }
  }, [isAuthenticated, navigate]);
  return (
    <div className="dashboard-flex-wrapper">
      <NavBar />
      <div className="dashboard-main">
        <div className="dashboard-header" id="atticus-dashboard-header">
            <p className="dashboard-header-main-text">Welcome to Jurist AI!</p>
            <AccountDropdown user={user}/>
        </div>

        <main className="dashboard-content">
          <Outlet/>
        </main>
      </div>
    </div>
  );
};

export default Layout;

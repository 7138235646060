import React, { useState } from 'react';
import './AtticusAI.css';
import { atticusAIDataArray, atticusMotionData } from './atticusMotionData';
import PlayIcon from '@assets/images/icons/play-icon.svg';
import fileIcon from '@assets/images/icons/file-with-background.svg';
import eyeIcon from '@assets/images/icons/eye.png';
import closeIcon from '@assets/images/icons/close-icon.svg';
import atticusAI from '@assets/images/logos/atticus-ai-logo.svg';
import atticusleftImage from '@assets/images/productImages/atticus-left-img.png';
import Button from '@shared/Button/Button';
import { Link } from 'react-router-dom';

const AtticusAI = () => {
  const [showGeneratingMotion, setShowGeneratingMotion] = useState(true);
  const [showMotionPrompt, setShowMotionPrompt] = useState(true);
  const [motionPrompt, setMotionPrompt] = useState('');

  return (
    <section className="atticus-ai-section">
      <div className="atticus-ai-container">
        <div className="atticus-ai-content">
          <div className="atticus-ai-left-image-container">
            <img className="atticus-ai-left-image" src={atticusleftImage} alt="atticus" />
            <div className="atticus-ai-top-right-card">
              <div className="atticus-ai-file-container">
                <img src={fileIcon} alt="no file" />
                <div className="atticus-ai-top-right-card-text ">Motion for Detention</div>
                <Button className="atticus-ai-summarize-button button-disabled">
                  <img src={eyeIcon} alt="" /> <span>Summarize</span>
                </Button>
              </div>
            </div>
            {showGeneratingMotion && (
              <div className="atticus-ai-left-center-card">
                <div className="atticus-ai-data-driven-container">
                  <div className="atticus-ai-data-driven-header-container">
                    <h4>Generating Your Motion</h4>
                    <img src={closeIcon} alt="" className="atticus-ai-close-icon" onClick={() => setShowGeneratingMotion(true)} />
                  </div>
                  {atticusMotionData?.map((data, index) => {
                    return (
                      <div className={`atticus-ai-data-driven`} key={index}>
                        <img src={data.image} alt="logo" className="atticus-motion-icons" />
                        <span>{data.text}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {showMotionPrompt && (
              <div className="atticus-ai-bottom-right-card">
                <div className="atticus-ai-bottom-right-card-heading">
                  <h4>Why are you filing this motion?</h4>
                  <img src={closeIcon} alt="" className="atticus-ai-close-icon" onClick={() => setShowMotionPrompt(true)} />
                </div>
                <div className="atticus-ai-bottom-right-card-body">
                  <textarea
                    name="motionPrompt"
                    disabled={true}
                    value={motionPrompt}
                    onChange={(e) => setMotionPrompt(e.target.value)}
                    placeholder="Describe motion's purpose"
                  />
                  <Button className="button--primary button--full-width button-disabled">Continue</Button>
                </div>
              </div>
            )}
          </div>

          <div className="atticus-ai-right-content">
            <div className="atticus-ai-icon-section">
              <Link to="https://www.atticusai.org/">
                <img src={atticusAI} alt="atticus ai" className="atticus-ai-text-logo" />
              </Link>
            </div>
            <div className="atticus-ai-title-section">The Future of Criminal Defense</div>
            <div className="atticus-ai-paragrapgh-content">
              AtticusAI is revolutionizing the field of criminal defense with its <strong>advanced AI capabilities</strong>, designed to assist attorneys in
              focusing on what they do best. Acting as a comprehensive AI-powered legal assistant, AtticusAI offers a suite of tools including a{' '}
              <strong>
                web dashboard, document management, full motion and brief drafting, chatbot, research citations, discovery analysis, response motion
                recommendations, and hallucination/error detection.
              </strong>
            </div>
            <div className="atticus-ai-features-data">
              {atticusAIDataArray &&
                atticusAIDataArray.map((feature, index) => (
                  <div key={index} className="feature-item">
                    <img src={feature.image} alt="left arrow" className="feature-icon" />
                    <span className="feature-text">{feature.text}</span>
                  </div>
                ))}
            </div>
            <div className="atticus-ai-button-section">
              <Button className="button--primary">
                <img src={PlayIcon} alt="play icon" />
                Watch Video
              </Button>
              <Button>
                <Link to="https://www.atticusai.org/">Visit AtticusAI</Link>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AtticusAI;

import React, { useEffect, useState } from 'react';
import './ManageSubscription.css';
import PageTitle from '../../Shared/PageTitle/PageTitle';
import Button from '@shared/Button/Button';
import { subscriptionDetail, paymentMethods, recentHistoryData } from './subscriptionData';
import { ReactComponent as Edit } from '@assets/images/icons/edit-icon.svg';
import { ReactComponent as Trash } from '@assets/images/icons/trash-icon.svg';
import { ReactComponent as Download } from '@assets/images/icons/download-icon.svg';
import { Link, useNavigate } from 'react-router-dom';
import VisaLogo from '@assets/images/icons/visa-icon.png';
import MasterCardLogo from '@assets/images/icons/mastercard-icon.png';
import DataTable from '@shared/DataTable/DataTable';
import Drawer from '@shared/Drawer/Drawer';
import ChangePlan from './ChangePlan/ChangePlan';
import AddNewPlan from './AddNewPlan/AddNewPlan';
import ConfirmationModal from '@shared/ConfirmationModal/ConfirmationModal';
import { getSubscriptionBySubId, getSubscriptionsByUserId} from '@lib/apis/resources/subscription';
import { toSentenceCase, timeStampToMDY } from '@lib/utils/strings';
import { useSelector } from 'react-redux';
import { getUser } from '@lib/apis/resources/user';


const ManageSubscription = () => {
  const navigate = useNavigate();
  const subscriptionDetails = subscriptionDetail();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openAddNewDrawer, setOpenAddNewDrawer] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(null);
  const userId = useSelector((state) => state.user.cognito_user_id);
  const userEmail = useSelector((state) => state.user.email);
  const [organizationId, setOrganizationId] = useState(null);

  console.log(process.env.REACT_APP_ATTICUS_API_URL);
  useEffect(() => {
    const fetchUserInformation = async () => {
      const userResponse = await getUser(userEmail);
      if (userResponse.success) {
        setOrganizationId(userResponse.data.user.organizationId);
      }
    }

    // const fetchSubscription = async () => {
    //   const response = await getSubscriptionBySubId();
    //   if (response.success) {
    //     const currentPlan = response.data.subscription;
    //     setCurrentPlan(currentPlan);
    //   }
    // }

  const fetchSubscriptionForUser = async () => {
      const response = await getSubscriptionsByUserId(userId);
      if (response.success) {
        const subscriptions = response.data.subscriptionsByUser[0] || null
        setCurrentPlan(subscriptions);
      }
    }
    // fetchSubscription();
    fetchUserInformation()
    fetchSubscriptionForUser()
  }, []);


  const togglePlanDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const toggleAddNewDrawer = () => {
    setOpenAddNewDrawer(!openAddNewDrawer);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const getSuccessParam = urlParams.get('success');
    const getCanceledParam = urlParams.get('canceled');

    if (getSuccessParam === 'true') {
      setShowSuccessModal(true);
    } else if (getCanceledParam === 'true') {
      setShowCancelModal(true);
    }

  }, []);

  const closeModal = () => {
    setShowSuccessModal(false);
    setShowCancelModal(false);
    navigate('/dashboard/subscriptions/');
  };

  const renderRow = (row, index) => (
    <>
      <td style={{ width: '350px' }}>{row.plan}</td>
      <td>{row.renewalDate}</td>
      <td style={{ width: '60px' }}>
        {row.action && (
          <Button className="action-donwload-button">
            <Download />
          </Button>
        )}
      </td>
    </>
  );

  return (
    <>
      <PageTitle title="Subscriptions" />
      
      <div className="main-subscription-container">
      <div className="subscription-card">
        <div className="card-header">
          <h4>Subscription Details</h4>

          { currentPlan && <Button className="active-subscription-btn">Active Subscription</Button>}
        </div>
        {currentPlan ? (
          <>
            <div className="card-content">
              <div key={`${currentPlan.pricingTier}-current`} className="card-content-item">
                <span>Plan</span>
                <span className="value">{`${toSentenceCase(currentPlan.pricingTier)}`}</span>
              </div>
              <div key={`${currentPlan.AmountCharged}-current`} className="card-content-item">
                <span>Billing Amount</span>
                <span className="value">{`$${currentPlan.AmountCharged}/mo`}</span>
              </div>
              <div key={`${currentPlan.endDate}-current`} className="card-content-item">
                <span>Next Bill Date</span>
                <span className="value">{`${timeStampToMDY(currentPlan.endDate)}`}</span>
              </div>
              <div key={`${currentPlan.pricingStrategyId}-current`} className="card-content-item">
                <span>Plan Features</span>
                <span className="value">{`${toSentenceCase(currentPlan.pricingStrategyId)}`}</span>
              </div>
            </div>
            <div className="subscriptions-button-section">
              <Button className="cancel-btn">Cancel Subscription</Button>
              <Button className="change-plan-btn button--primary" onClick={togglePlanDrawer}>Change Plan</Button>
            </div>
          </>
        ) : (
          <div>
            <p>No active subscription found. Please subscribe to a plan.</p>
            <Button className="change-plan-btn button--primary" onClick={togglePlanDrawer}>Choose Plan</Button>
          </div>
        )}
      </div>
   

      <div className="subscription-card">
        <div className="card-header">
          <h4>Payment Methods</h4>
          <Button className="add-new-btn button--primary" onClick={toggleAddNewDrawer}>Add New</Button>
        </div>
        <div className="card-content">
          {paymentMethods.map((method, index) => (
            <div key={method.id} className="payment-card">
              <div className="payment-details">
                <img src={method.cardType === 'Visa' ? VisaLogo : MasterCardLogo} alt={method.cardType} />
                <div className="payment-content">
                  <p>
                    {method.cardType} {method.cardNumber}
                  </p>
                  <p>Expires {method.expiry}</p>
                </div>
              </div>
              <div className="payment-actions">
                {method.isPrimary ? (
                  <Button className="primary-card-btn">Primary Card</Button>
                ) : (
                  <Button className="transparent-btn">Make Primary</Button>
                )}
                <Link>
                  <Edit />
                </Link>
                <Link>
                  <Trash />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
 


      <div className="recent-history-container">
        <h4>Recent History</h4>
        <DataTable
          headings={['Plan', 'Renewal Date', 'Action']}
          data={recentHistoryData}
          renderRow={renderRow}
          isPagination={false}
        />
      </div>

      <Drawer title={'Change Plan'} isOpen={openDrawer} onClose={togglePlanDrawer}>
        {' '}
        <ChangePlan currentPlan={currentPlan} organizationId={organizationId} />
      </Drawer>

      <Drawer title={'Plan: Private Attorneys'} isOpen={openAddNewDrawer} onClose={toggleAddNewDrawer}>
        {' '}
        <AddNewPlan />
      </Drawer>

      {showSuccessModal && (
        <ConfirmationModal 
          isOpen={showSuccessModal}
          heading="Payment Successful"
          successText = "Okay Thanks"
          onSuccess={closeModal}
          showCancelButton={false}
        >
          <p> 
            Your payment was successful! Thank you for subscribing.
          </p>
        </ConfirmationModal>
      )}

      {showCancelModal && (
        <ConfirmationModal
          isOpen={showCancelModal}
          heading="Payment Canceled"
          successText = "Okay Thanks"
          onSuccess={closeModal}
          showCancelButton={false}
        >
          <p>
            Your payment was canceled. Please try again or contact support.
          </p>
        </ConfirmationModal>
      )}
    </>
  );
};

export default ManageSubscription;

import React, { useState } from "react";
import Button from "@shared/Button/Button";
import TextField from "@shared/TextField/TextField";
import LoaderSpinner from "@shared/LoaderSpinner/LoaderSpinner";
import "./ForgotPassword.css";
import { sendPasswordResetRequest } from "@lib/apis/password-reset";
import JusristAiLogo from '@assets/images/juristAIInsights/jurist-logo-full.png';

import { Link } from 'react-router-dom';

const ForgotPassword = () => {
  const [formData, setFormData] = useState({ email: "" });
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [showButton, setshowButton] = useState(true);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

    if (e.target.name === "email" && e.target.value) {
      setResponseMessage("");
    }
  }

  const handleClick = async (event) => {
    event.preventDefault();
    if (!formData.email) {
      setResponseMessage("Please provide an email address.");
      return;
    }
    setLoading(true);
    const success = await sendPasswordResetRequest(formData.email);
    if (success) {
      setResponseMessage("Password reset email sent.");
    } else {
      setResponseMessage("Error sending password reset email.");
    }
    setLoading(false);
    setshowButton(false);
  }
  return (
    <main className="forgot-password-page">
      <section className="fp-left">
        <img src={JusristAiLogo} alt="jurist-ai-logo" className="auth-logo" />
        <h2 className="forgot-heading">We are revolutionizing the law.</h2>
      </section>
      <section className="fp-right">
        <div className="fp-form-container">
          <form>
            <h1>Reset Password</h1>
            <p className="">Provide your email to reset your password</p>
            <TextField
              placeholder="Email address"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />

            <div className="forgot-button-group">
              {showButton && (
                <Button type="submit" onClick={handleClick} className="button--primary fp-page-button mt-1" disabled={loading}>
                  {loading
                    ? <LoaderSpinner messages={''} hideText={false} color='var(--color-black)' width={20} />
                    : 'Reset Password'
                  }
                </Button>
              )}


              <Button type="submit" className=" login-back-button my-2">
                LogIn
              </Button>
              {!formData.email ? <p className={`forgot-password-error ${formData.email ? 'd-none' : ''}`}>{responseMessage}</p> : <p className="forgot-password-success">{responseMessage}</p>}

              {/* <p className={`fp-response-message  ${responseMessage && !responseMessage.includes("successfully") ? "forgot-password-error" : "forgot-password-success"}`}>
                {responseMessage}
              </p> */}

            </div>
          </form>
        </div>
      </section>
    </main>
  );
}

export default ForgotPassword;
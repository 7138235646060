import axios from 'axios';
import { getAuthHeaders, graphQlUrl } from '../utilities';
import { getUser } from './user';

const DEFAULT_USER_ID = '84083408-a051-70d1-f910-749d49645793'
const SUB_ID = 'sub_12345'

export const getSubscriptionsByUserId = async (userId = DEFAULT_USER_ID) => {

  const query = `query MyQuery {
  subscriptionsByUser(userId: "${userId}") {
    subsId
    organizationId
    appId
    userId
    startDate
    endDate
    pricingStrategyId
    pricingTier
    AmountCharged
  }
}
  `
    
  try {
    const encodedQuery = encodeURIComponent(query);
    const URL = `${graphQlUrl}${encodedQuery}`;
    const response = await axios.get(URL);
    return {
      success: true,
      data: response.data.data
    }; // Return the data

  } catch (error) {
    console.error('Error getting case:', error);
    return {
      success: false,
      message: error.message
    };
  }

}

export const getSubscriptionBySubId = async (subId = SUB_ID) => {

  const query = `query MyQuery {
  subscription(subsId: "${subId}") {
    subsId
    organizationId
    appId
    userId
    startDate
    endDate
    pricingStrategyId
    pricingTier
    AmountCharged
    }
  }
  `
      
    try {
      const encodedQuery = encodeURIComponent(query);
      const URL = `${graphQlUrl}${encodedQuery}`;
      const response = await axios.get(URL);
      return {
        success: true,
        data: response.data.data
      }; // Return the data
  
    } catch (error) {
      console.error('Error getting case:', error);
      return {
        success: false,
        message: error.message
      };
    }

}

// export const getSubscriptionForUserAndAppId = async (userId, appId) => {
//   const response = await getSubscriptionsByUserId(userId);
//   if (response.success) {
//     const subscriptions = response.data.subscriptionsByUser;
//     const userSubscription = subscriptions.find(sub => sub.appId === appId);
//     return userSubscription || null;
//   }
//   return null;
// }

export const getOrSetSubscription = async (userId, appId, userEmail) => {
  let subscription = {};
  const response = await getSubscriptionsByUserId(userId);
  if (response.success) {
    if (response.data.subscriptionsByUser.length > 0) {
      // User has subscriptions
      // Check to see if there is a subscription for the current appId
      const subscriptions = response.data.subscriptionsByUser;
      const filteredSubscription = subscriptions.filter((sub) => sub.appId === appId);
      if (filteredSubscription.length > 0) {
        subscription = filteredSubscription[0];
        subscription.organizationId = filteredSubscription[0].organizationId;
      } else {
        subscription.subsId = `sub_${userId}_${appId}_default`;
      }
    } else {
      // User has no subscriptions
      // Get OrgId for checkout page
      const userResponse = await getUser(userEmail);
      subscription.organizationId = userResponse.data.user.organizationId;
      subscription.subsId = `sub_${userId}_${appId}_default`;
    }
  } else {
    // Error fetching subscriptions
    console.error("Error fetching subscriptions: ", response);
    return null
  }
  return subscription;
}

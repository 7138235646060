import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "@assets/images/logos/logo.png";
import ArrowDown from "@assets/images/icons/arrow-down.png";
import Button from "../Button/Button";
import classNames from "classnames";
import "./Nav.css";
import { useSelector } from "react-redux";

const Nav = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const user = useSelector((state) => state?.user);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <div className="nav-container">
        <Link to={"/"} className="logo">
          <img src={Logo} alt="JuristAI" />
        </Link>
        <nav className={`nav--list-container ${isMenuOpen ? "nav--open" : ""}`}>
          <ul className="nav__list">
            <li className="nav__item">
              <NavLink exact="true" to="/" className={({ isActive }) => classNames("nav__link ", { "nav__link--active": isActive })}>
                Home
              </NavLink>
            </li>
            <li className="nav__item">
              <NavLink to="/products" aria-disabled className={({ isActive }) => classNames("nav__link", { "nav__link--active": isActive })}>
                Our Products
              </NavLink>
            </li>
            <li className="nav__item nav__item--dropdown">
              <span className="nav__link dropdown-link">
                Pricing <img src={ArrowDown} className="arrow-icon" alt="Arrow Down button-disabled" />
              </span>
              <ul className="dropdown">
                <li className="dropdown__item">
                  <NavLink
                    to="/pricing-cards"
                    className={({ isActive }) =>
                      classNames('dropdown__link borderd', {
                        'dropdown__link--active': false,
                      })
                    }
                  >
                    Pricing
                  </NavLink>
                </li>
                <li className="dropdown__item">
                  <NavLink
                    to="/private-attorney"
                    className={({ isActive }) =>
                      classNames('dropdown__link borderd', {
                        'dropdown__link--active': false,
                      })
                    }
                  >
                    Private Attorneys
                  </NavLink>
                </li>
                <li className="dropdown__item">
                  <NavLink
                    to="/small-firm"
                    className={({ isActive }) =>
                      classNames('dropdown__link borderd', {
                        'dropdown__link--active': false,
                      })
                    }
                  >
                    Small Firms
                  </NavLink>
                </li>
                <li className="dropdown__item">
                  <NavLink
                    to="/big-firm"
                    className={({ isActive }) =>
                      classNames('dropdown__link', {
                        'dropdown__link--active': false,
                      })
                    }
                  >
                    Big Firms
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="nav__item">
              <NavLink to="/contact" className={({ isActive }) => classNames("nav__link ", { "nav__link--active": isActive })}>
                Book A Demo
              </NavLink>
            </li>
            <li className="nav__item ">
              <NavLink to="/blog" className={({ isActive }) => classNames("nav__link", { "nav__link--active": isActive })}>
                Blog
              </NavLink>
            </li>

            {user ? (
              <>
                <NavLink to="/dashboard">
                  <li className="nav__item nav__item--cta">
                    <Button className="button--primary">Dashboard</Button>
                  </li>
                </NavLink>

                <NavLink to="/logout">
                  <li className="nav__item nav__item--cta">
                    <Button className="button--transparent">Logout</Button>
                  </li>
                </NavLink>
              </>
            ) : (
              <>
                <NavLink to='/login'>
                  <li className="nav__item nav__item--cta">
                    <Button className="button--transparent button-disabled">Login</Button>
                  </li>
                </NavLink>

                <NavLink to='/signup'>
                  <li className="nav__item nav__item--cta">
                    <Button className="button--primary button-disabled ">Sign Up</Button>
                  </li>
                </NavLink>
              </>
            )}

          </ul>
        </nav>
        <button className="menu-toggle" onClick={toggleMenu}>
          <span className="menu-icon"></span>
        </button>
      </div>
    </header>
  );
};

export default Nav;

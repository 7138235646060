import React from 'react';
import './BlogPost.css';
import Nav from '@shared/Nav/Nav';
import Footer from '@shared/Footer/Footer';
import dataSecurity from '@assets/images/juristAIInsights/data-security-full-image.svg';
import { Link, useParams } from 'react-router-dom';
import shareIcon from '@assets/images/icons/share-icon.svg';
import heartIcon from '@assets/images/icons/heart-icon.svg';
import eyeIcon from '@assets/images/icons/eye.png';
import { insightsData, tableOfContent } from '../../Home/JuristAIInsights/juristAIInsightsData';
import rightArrowGreen from '@assets/images/icons/arrow-right-green-gradient.svg';
import Card from '@shared/Card/Card';

const BlogPost = () => {
  const { id } = useParams();
  const blog = insightsData?.find((post) => post.id === Number(id));

  return (
    <section className="blog-post-main-section-container">
      <Nav />
      <div className="blog-post-hero-section-image">
        <img src={dataSecurity} alt={blog.title} />
        <div className="blog-post-hero-title">
          <h1>{blog.title}</h1>
        </div>
      </div>

      <div className="blog-post-content-container">
        <div className="blog-post-left-content">
          <div className="blog-post-title-section">
            <div className="blog-post--title">
              <h1>Introduction</h1>
              <p>{blog.titleText}</p>
            </div>
          </div>

          <div className="blog-post-content-section">
            <p>{blog.paragraph1}</p>
            <div className="legal-industry--title">
              <h2>The Role of AI in the Legal Industry</h2>
              <p>{blog.paragraph2}</p>
              <p className="paragraph--three">{blog.paragraph3}</p>
            </div>
          </div>
        </div>

        <div className="blog-post-right-content">
          <div className="custom-icons">
            <div className="images--section">
              <img src={heartIcon} alt="" />
              24.5k
            </div>
            <div className="images--section">
              <img src={eyeIcon} alt="" />
              50k
            </div>
            <div className="images--section">
              <img src={shareIcon} alt="" />
              206
            </div>
          </div>

          <div className="table-of-content--main">
            <div className="table-of-content">
              <h4>Table of Contents</h4>
              <ul className="content-points--section">
                {tableOfContent?.map((item, index) => (
                  <li key={index} className="content--items">
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-post-similar-news-container">
        <div className="similar-new-title">
          <h3>Similar News</h3>
          <Link to="/blog" className="read-more--button">
            View All
            <img src={rightArrowGreen} alt="" />
          </Link>
        </div>

        <div className="insights-grid">
          <Card data={insightsData?.slice(0,3)} />
        </div>
      </div>

      <Footer />
    </section>
  );
};

export default BlogPost;

import axios from "axios";

const URL = 'https://lu4t863gw7.execute-api.us-east-1.amazonaws.com/devv1/stripegraph';

function getTodayISODate() {
  const today = new Date();
  return today.toISOString().split('T')[0] + "T00:00:00Z";
}

function getFutureISODate(days) {
  const futureDate = new Date();
  futureDate.setDate(futureDate.getDate() + days);
  return futureDate.toISOString().split('T')[0] + "T00:00:00Z";
}


export const createStripeCheckoutLink = async (params) => {

  const body = {
    "path": "/create-checkout-session",
    "httpMethod": "POST",
    "body": {
      "lookup_key": `atticus${params.tier.tier}`,
      "userId": params.userId,
      "pricingStrategyId": params.pricingStrategyId,
      "accessLevel": params.accessLevel,
      "subscription": {
        "subsId": params.subsId,
        "organizationId": params.organizationId,
        "appId": params.appId,
        "userId": params.userId,
        "startDate": getTodayISODate(),
        "endDate": getFutureISODate(params.days),
        "pricingStrategyId": params.pricingStrategyId,
        "pricingTier": params.pricingTier,
        "amountCharged": params.amountCharged,
        "accessLevel": params.accessLevel,
      },
      "pricingStrategy": {
        "pricingStrategyId": params.pricingStrategyId,
        "appId": params.appId,
        "strategyType": params.strategyType,
        "tiers": params.tiers,
      }
    }
  }
  const headers = {
    'Content-Type': 'application/json',
  };
  console.log("pretty body: ", body.body);
  body.body = JSON.stringify(body.body);
  try {
    const response = await axios.post(URL, body, headers);
    if (response.data.statusCode !== 200) {
      console.error("Error creating Stripe checkout link: ", response);
      return null;
    }
    const data = JSON.parse(response.data.body);
    const checkoutUrl = data.checkoutUrl;
    return checkoutUrl || null;
  } catch (error) {
    console.error('Error creating Stripe checkout link:', error);
    throw error;
  }
}
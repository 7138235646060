import leftArrow from '@assets/images/icons/arrow-left-orange-icon.svg';
import loadingIcon from "@assets/images/icons/orange-loading-icon.svg";
import arrowRightBlackIcon from "@assets/images/icons/right-arrow-gray.svg";

export const seriesAIDataArray = [
  {
    image: leftArrow,
    text: 'AI-Driven Document Generation',
  },
  {
    image: leftArrow,
    text: 'Lifecycle-Based Document Management',
  },
  {
    image: leftArrow,
    text: 'Dynamic Equity Distribution',
  },
  {
    image: leftArrow,
    text: 'Cap Table Management',
  },
  {
    image: leftArrow,
    text: 'Data Security & Encryption',
  },
  {
    image: leftArrow,
    text: 'Scalable for Startups & Beyond',
  },
  {
    image: leftArrow,
    text: 'Secure E-Signature Integration',
  },
  {
    image: leftArrow,
    text: 'Collaboration Tools for Teams',
  },
];

export const generatingDocumentData = [
  {
    image: loadingIcon,
    text: "Loading user information",
  },
  {
    image: arrowRightBlackIcon,
    text: "Loading company background",
  },
  {
    image: arrowRightBlackIcon,
    text: "Loading SAFE template",
  },
  {
    image: arrowRightBlackIcon,
    text: "Adding user inputs",
  },
];


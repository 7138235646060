import React, { useState, useEffect } from 'react';
import { RotatingLines } from 'react-loader-spinner';
import './LoaderSpinner.css';

/**
 * LoaderSpinner Component
 * Displays a rotating loader animation with optional rotating messages.
 *
 * Props:
 * - messages: Array of messages to display sequentially (default: loading messages).
 * - hideText: Boolean to hide or show the messages (default: false).
 * - color: Color of the loader (default: 'silver').
 * - width: Width of the loader in pixels (default: 40).
 * - animationDuration: Duration of the loader animation in seconds (default: 0.75).
 * - interval: Interval in milliseconds for rotating messages (default: 2000).
 */
const LoaderSpinner = ({
    messages = ['Loading...', 'Please wait...', 'Almost there...', 'Just a moment...', 'Hold on...'],
    hideText = false,
    color = 'silver',
    width = 40,
    animationDuration = 0.75,
    interval = 2000,
}) => {
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

    useEffect(() => {
        if (hideText) return;

        const intervalId = setInterval(() => {
            setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
        }, interval);

        return () => clearInterval(intervalId);
    }, [messages.length, hideText, interval]);

    return (
        <div className="loader-spinner-container">
            <div className="loader-spinner-rotating-lines">
                <RotatingLines strokeColor={color} strokeWidth="4" animationDuration={animationDuration} width={width} visible={true} />
            </div>

            {!hideText && <div className="loader-text">{messages[currentMessageIndex]}</div>}
        </div>
    );
};

export default LoaderSpinner;

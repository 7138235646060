import React from "react";
import "./Card.css";
import { Link } from "react-router-dom";
import rightArrowGreen from '@assets/images/icons/arrow-right-green-gradient.svg'

const Card = ({ data }) => {
  return data.map((item, index) => (
    <div key={index} className="card--container">
      <Link to={item.link} className="button-disabled">
        <img src={item.image} alt={item.title} className="card--image" />
      </Link>
      <div className="card--content">
        <p className="card--date">{item.date}</p>
        <h3 className="card--title">{item.title}</h3>
        <p className="card--description">{item.description}</p>
        <Link to={item.link} className="card--link">
          Read More <img src={rightArrowGreen} alt="" />
        </Link>
      </div>
    </div>
  ));
};

export default Card;
